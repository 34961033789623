import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { PalleteList } from '../../../components/PalleteList';
import { TabLinks } from '../../../components/TabLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "cores",
      "style": {
        "position": "relative"
      }
    }}>{`Cores`}</h1>
    <TabLinks items={[{
      title: 'Paleta',
      href: '/design-guidelines/color'
    }, {
      title: 'Utilização',
      href: '/design-guidelines/color/usage'
    }]} mdxType="TabLinks" />
    <p>{`No Bold, as cores são usadas para expressar a interatividade dos componentes, situação e enfatizar conteúdo visual e textual. Todas as paletas foram construídas de acordo com padrões de acessibilidade, cumprindo o contraste mínimo de elementos interativos e texto com o plano de fundo. As paletas também foram projetadas de forma temática e mantêm o contraste ideal de todos os componentes, além de funcionar em sentido inverso, no modo `}<em parentName="p">{`dark`}</em>{`.`}</p>
    <PalleteList mdxType="PalleteList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      